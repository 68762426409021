$call-in-volume-prediction: '.CallInVolumePrediction';

#{$call-in-volume-prediction} {
  &__tile {
    margin: 10px 0;

    &:first-child {
      margin-top: 40px;
    }
  }

  &__content {
    min-height: 555px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}