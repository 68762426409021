.sl-chip {
  display: inline-block;
  border-radius: 49px;
  margin: 0;
  padding: 5px 10px;
  border-width: 1px;
  border-style: solid;
  text-transform: capitalize;
  &--outline {
    background: transparent !important;
  }
}
