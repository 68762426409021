@import "src/style/mixins";

.sl-page {
  @include paragraph--7();
  background: var(--color-page);
  min-height: 100%;

  * {
    box-sizing: border-box;
  }
}
