.sl-menu {
  background-color: rgb(0, 0, 0);
  border: 1px solid rgba(147, 149, 151, 1);
  border-radius: 12px;
  padding: 8px;
  margin-bottom: 0px;
  box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.1);
  width: 208px;

  &.narrow {
    width: 160px;
  }

  &.wide {
    width: 270px;
  }
}
