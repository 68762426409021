.sl-title-time-update-wrapper-relative {
  position: relative;
  height: 0px;
  width: 0px;

  .sl-title-time-update-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: rgb(0, 0, 0);
    width: 256px;
    height: 48px;
    border-radius: 25px;
    padding-left: 21px;
    padding-right: 22px;
    letter-spacing: 0.5px;
  }
}
