.sl-main-header {
  background-color: var(--color-contrast-3);
  color: var(--color-text);

  .sl-text {
    display: inline-block;
    margin-left: 10px;
    text-transform: capitalize;
  }

  .sl-item .sl-text{
    display: inline-block;
    margin-left: 10px;
    text-transform: none;
  }

  .sl-section__inner {
    padding-bottom: 0;
    display: flex;
    height: 96px;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    color: white;
    font-size: 28px;
    font-weight: 400;
    text-decoration: none;
  }

  &__left {
    align-self: center;
  }

  &__right {
    align-self: center;
    display: flex;
    align-items: flex-start;

    & > * {
      margin-right: 10px;
    }
  }
}