.signal-alert {
  all: initial;

  table,
  th,
  thead,
  td,
  tbody,
  tr,
  tr:nth-child(odd),
  tr:nth-child(even) {
    letter-spacing: initial;
    width: 100%;
    font-size: initial;
    text-align: initial;
    background-color: initial;
    height: initial;
  }

  &-error {
    margin-top: 32px;
  }

  .alert-row {
    & > div {
      height: 417px;
    }
  }
  .alert-barchart-outer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 40px 0;
  }

  .sl-page .sl-section:nth-child(2) {
    margin-top: 0;
  }
}
