.Sl-styled-input {
  display: flex;
  background: rgba(255, 255, 255, 0.1);
  height: 48px;
  border-radius: 29px;
  color: white;
  .Sl-styled-input-icon {
    display: flex;
    flex: 1;
    justify-content: center;
    height: 100%;
    align-items: center;
  }
  .Sl-styled-input-input {
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    color: white;
    word-wrap: break-word;
    outline: none;
    display: inline-flex;
    flex: 11;
    tap-highlight-color: transparent;
    margin-top: -33px;
    height: 44px;
    font-size: 16px;
    overflow: auto;
    box-sizing: border-box;
    height: 34px !important;
    position: relative;
    top: 40px;
  }
  .delete-x {
    display: flex;
    flex: 1;
    justify-content: center;
    height: 100%;
    align-items: center;
    cursor: pointer;
    svg {
      height: 20px;
      width: 20px;
    }
  }
}
