@import "src/style/mixins";

.sl-header {
  box-sizing: border-box;
  padding-top: var(--spacing-lg);
  padding-bottom: 29px;

  &__horizontal-line {
    border-bottom: 1px solid var(--color-contrast-3);
  }

  &__bottom-margin {
    margin-bottom: 48px;
  }

  h1,
  h2 {
    margin: 0;
  }

  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__start {
    margin-top: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    > *:not(:first-child) {
      margin-left: 20px;
    }
  }

  &__end {
    margin-top: auto;
  }
}
