.sl-buttons-group {
  background-color: rgba(255, 255, 255, 0.05);
  display: flex;
  height: 48px;
  border-radius: 25px;

  &__option {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
    transition: .1s ease-in background-color;
    border-radius: 25px;

    &:hover,
    &--selected {
      background-color: rgba(255, 255, 255, 0.15);
    }
  }
}