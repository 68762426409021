.sl-admin-alert-toggle-wrapper {
  width: 185px;
  height: 48px;
  background-color: #ffffff;
  display: inline-block;
  border-radius: 42px;
  padding-left: 16px;
  line-height: 52px;

  &--disabled {
    cursor: not-allowed;
  }

  .sl-admin-alert-toggle-title {
    display: inline-block;
    text-align: center;
    color: rgba(98, 105, 255, 1);
    width: 110px;
    font-size: 16px;
  }
  .SL-alert-config-switch {
    display: inline-block;
    width: 20px;

    .switch {
      margin-top: 21px;
    }
  }
  .sl-admin-alert-toggle-title-off {
    color: rgba(147, 149, 151, 1);
  }
}
