$paw-graph: '.PawGraph';

#{$paw-graph} {
  width: 800px;
  height: 555px;
  margin: 0 auto;
  position: relative;
  color: #fff !important;

  &__label {
    font-family: "Wigrum";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
  }

  &__tooltip {
    font-weight: normal !important;
  }

  &__info {
    position: absolute;
    visibility: hidden;
    padding: 5px;
    min-width: 60px;
    min-height: 60px;
    display: flex;
    align-items: center;
    background: transparent;
    color: #fff;
    transition: .3s visibility ease-in;
    transform: translate(400,400) !important;

    &.right {
      text-align: left;
    }

    &.left {
      text-align: right;
      justify-content: flex-end;
    }

    &.top {
      text-align: center;
      align-items: flex-end;
    }

    &.bottom {
      align-items: flex-start;
      text-align: center;
      justify-content: center;
    }
  }

  &__element {
    stroke: transparent;
    stroke-width: 2;
    cursor: pointer;
    shape-rendering: geometricPrecision;

    &:hover {
      stroke: white;

      #{$paw-graph}__rect {
        -webkit-filter: drop-shadow( 0px 0px 10px rgba(#fff, .7));
        filter: drop-shadow( 0px 0px 10px rgba(#fff, .7));
      }

      #{$paw-graph}__label {
        stroke: transparent;
      }

      #{$paw-graph}__tooltip {
        font-weight: normal !important;
        -webkit-filter: none;
        filter: none;
        stroke-width: 0;
      }
    }
  }
}

#PawGraphContainer {
  height: 100%;
}
