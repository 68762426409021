@import "src/style/mixins";

.sl-item {
  margin: 0;
  padding: 12px 16px;
  line-height: 22px;

  &.hasFocus {
    padding: 0;
    margin: 12px 16px;
    border: 1px solid #dee0e2;
  }

  .sl-item-input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 8px;
  }

  .sl-item-text {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    span {
      display: inline-block;
    }
    .sl-icon {
      display: inline-block;
    }
  }

  &.multiline {
    .sl-item-text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      > * {
        margin: 8px 0;
        &:first-child,
        &:last-child {
          margin: 4px 0;
        }
      }
    }
  }

  &.actionable:hover {
    background-color: rgba(75, 75, 75, 0.8);
    cursor: pointer;
  }

  .sl-item-start {
    margin-right: 8px;
  }

  .sl-item-text .sl-text {
    margin: 0;
    padding: 0;
  }

  & svg {
    & path {
      fill: var(--paragraph--8-color);
    }
  }
}

.sl-link-item {
  color: var(--color-text);
  text-decoration: none;
}

.sl-separator {
  @include font-destressed-text();
  margin: 14px 9px 24px 9px;
  padding: 0;
  border-top: 1px solid var(--font-destressed-text-color);
}
