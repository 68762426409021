/***
  Use utilities to access many variables as class names in the JSX
***/

/************************************
Spacing
************************************/

.sl-margin {
  margin: var(--spacing-md);
  &--vertical {
    margin: var(--spacing-md) 0;
  }
  &--horizontal {
    margin: 0 var(--spacing-md);
  }
  &--left {
    margin-left: var(--spacing-md);
  }
  &--right {
    margin-right: var(--spacing-md);
  }
  &--top {
    margin-top: var(--spacing-md);
  }
  &--bottom {
    margin-bottom: var(--spacing-md);
  }
}

.sl-padding {
  &--vertical {
    padding: var(--spacing-md) 0;
  }
  &--horizontal {
    padding: 0 var(--spacing-md);
  }
  &--left {
    padding-left: var(--spacing-md);
  }
  &--right {
    padding-right: var(--spacing-md);
  }
  &--top {
    padding-top: var(--spacing-md);
  }
  &--bottom {
    padding-bottom: var(--spacing-md);
  }
}

/************************************
Transformations
************************************/

.sl-disabled {
  font-style: italic;
  cursor: not-allowed;
  opacity: 0.5;
}

/************************************
Colors
************************************/

.sl-color {
  &--primary {
    background: var(--color-primary);
    border-color: var(--color-primary);
    color: var(--color-primary);
  }
  &--secondary {
    background: var(--color-secondary);
    border-color: var(--color-secondary);
    color: var(--color-secondary);
  }
  &--tertiary {
    background: var(--color-tertiary);
    border-color: var(--color-tertiary);
  }
  &--quarternary {
    background: var(--color-quarternary);
    border-color: var(--color-quarternary);
  }
  &--contrast-0 {
    background: var(--color-contrast-0);
    border-color: var(--color-quarternary);
  }
  &--contrast-1 {
    background: var(--color-contrast-1);
    border-color: var(--color-quarternary);
  }
  &--contrast-2 {
    background: var(--color-contrast-2);
    border-color: var(--color-quarternary);
  }
  &--contrast-3 {
    background: var(--color-contrast-3);
    border-color: var(--color-quarternary);
  }
  &--contrast-4 {
    background: var(--color-contrast-4);
    border-color: var(--color-quarternary);
  }
  &--contrast-5 {
    background: var(--color-contrast-5);
    border-color: var(--color-quarternary);
  }
  &--contrast-6 {
    background: var(--color-contrast-6);
    border-color: var(--color-quarternary);
  }
  &--resolved {
    background: var(--color-contrast-2p5);
    border-color: var(--color-contrast-2p5);
    color: var(--color-positive);
  }
  &--destressed {
    color: var(--font-destressed-text-color);
  }
  &--danger {
    color: var(--color-danger);
  }
}

/************************************
Typography
************************************/
.sl-typography {
  &--align-left {
    text-align: left;
  }

  &--align-right {
    text-align: right;
  }
  &--align-center {
    text-align: center;
  }
  &--destressed {
    color: var(--font-destressed-text-color);
  }
  &--color-primary {
    color: var(--color-primary);
  }
}
