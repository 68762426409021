.sl-admin-threshold {
  margin-top: 5px;
  color: #fff;
  width: 100%;
  padding-bottom: 20px;

  &-label {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 10px;
  }

  &-description {
    margin-bottom: 16px;
    color: #C6C6C6;
  }
}
