.sl-overlay {
  height: 48px;
  background: #111;
  display: block;
  position: absolute;
  box-shadow: 0px 0px 6px 4px rgba(0, 0, 0, 0.2);
  border-radius: 24px;
  padding: 0 14px;
  .sl-text {
    color: var(--color-solid-grey);
  }
}
