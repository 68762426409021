.Sl-table-filter {
  color: var(--paragraph--8-color);
  background: var(--color-contrast-2);
  border-radius: 24px;
  display: inline-flex;
  justify-content: center;
  margin-right: 10px;
  margin-top: 10px;
  padding: 10px;
  width: 200px;
  height: 30px;
  box-sizing: border-box;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 13px;
  svg {
    pointer-events: none;
    width: 10px;
    height: 10px;
  }
}
