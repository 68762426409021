$tile: '.sl-tile';

#{$tile} {
  display: flex;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 13px 24px;
  box-sizing: border-box;
  align-items: center;
  transition: .1s background-color ease-in;
  cursor: pointer;
  border-left: 2px solid transparent;

  &--selected,
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &:hover {
    border-left: 2px solid transparent;
  }

  &--selected {
    border-left: 2px solid var(--white);
    &:hover {
      border-left: 2px solid var(--white);
    }
  }

  &__text {
    margin-left: 32px;

    &--title {
      margin: 0;
      padding-top: 12px;
      font-weight: 400;
    }

    &-wrapper {
      .sl-text {
        &:nth-child(2) {
          color: var(--color-destressed);
        }
      }
    }
  }

  &__chevron {
    width: 14px;
    height: 14px;
    margin-left: auto;
    color: var(--white);
  }

  &__icon {
    width: 40px;
    text-align: center;
    color: var(--white);
  }
}
