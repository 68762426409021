$height: 18px;
$thumb-height: 13px;
$track-height: 3px;

$upper-color: #DEE0E2;
$purple: #6269FF;

$upper-background: linear-gradient(to bottom, $upper-color, $upper-color) 100% 50% / 100% $track-height no-repeat transparent;
$lower-background: linear-gradient(to bottom, $purple, $purple) 100% 50% / 100% $track-height no-repeat transparent;

@function webkit-slider-thumb-shadow($i: 1) {
  $val: #{$i}px 0 0 -#{($thumb-height - $track-height) / 2} #{$upper-color};
  @if $i == 1 {
    @for $k from 2 through 1000 {
      $val: #{$val}, webkit-slider-thumb-shadow($k);
    }
  }
  @return $val;
}

.sl-slider {
  background-color: transparent;
  display: block;
  appearance: none;
  width: 90%;
  margin: 0;
  height: $height;
  overflow: hidden;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

// Webkit
.sl-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: $height;
  background: $lower-background;
}

.sl-slider::-webkit-slider-thumb {
  position: relative;
  appearance: none;
  height: $thumb-height;
  width: $thumb-height;
  background: $purple;
  border-radius: 100%;
  border: 0;
  top: 50%;
  margin-top: (-$thumb-height/2);
  box-shadow: webkit-slider-thumb-shadow();
  transition: background-color 150ms;
}

// Firefox
.sl-slider::-moz-range-track,
.sl-slider::-moz-range-progress {
  width: 100%;
  height: $height;
  background: $upper-background;
}

.sl-slider::-moz-range-progress {
  background: $lower-background;
}

.sl-slider::-moz-range-thumb {
  appearance: none;
  margin: 0;
  height: $thumb-height;
  width: $thumb-height;
  background: $purple;
  border-radius: 100%;
  border: 0;
  transition: background-color 150ms;
}

// Internet Exploder
.sl-slider::-ms-track {
  width: 100%;
  height: $height;
  border: 0;
  color: transparent;
  background: transparent;
}

.sl-slider::-ms-fill-lower {
  background: $lower-background;
}

.sl-slider::-ms-fill-upper {
  background: $upper-background;
}

.sl-slider::-ms-thumb {
  appearance: none;
  height: $thumb-height;
  width: $thumb-height;
  background: $purple;
  border-radius: 100%;
  border: 0;
  transition: background-color 150ms;
  top: 0;
  margin: 0;
  box-shadow: none;
}

.sl-slider:hover,
.sl-slider:focus {

  &::-webkit-slider-thumb {
    background-color: $purple;
  }
  &::-moz-range-thumb {
    background-color: $purple;
  }
  &::-ms-thumb {
    background-color: $purple;
  }
}
