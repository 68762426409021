.sl-admin-alerts-list-wrapper {
  margin-bottom: 106px;
  width: 100%;
  .sl-admin-alert-list-title {
    line-height: 24px;
    font-size: 24px;
    font-family: Wigrum;
    font-weight: 500;
    margin-bottom: 48px;
  }
  .sl-admin-alert-list-list {
    .sl-alert-title-title {
      line-height: 30px;
    }
  }
}
