.sl-horizontal-bar {
  height: 51px;
  display: flex;
  margin: 20px 0;
  &__label {
    width: 20%;
    justify-content: center;
    align-self: center;
  }
  &__inner {
    width: 80%;
    display: flex;
    background: var(--color-contrast-3);

    &:hover {
      .sl-horizontal-bar__inner__segment {
        opacity: 0.5;
        &:hover {
          opacity: 1;
        }
      }
    }

    &__segment {
      height: 100%;
      position: relative;

      .sl-tooltip {
        opacity: 0;
        top: -50px;
        left: -50%;
        z-index: 99;
        margin-left: -75px;
        width: 150px;
        pointer-events: none;
        transition: all ease-in-out 0.2s;
        position: absolute;
        span {
          display: block;
        }
      }

      &--alarm {
        background: var(--color-primary);
        cursor: pointer;
        border-right: 1px solid black;
        border-left: 1px solid black;

        &:hover .sl-tooltip {
          opacity: 1;
          top: -60px;
        }
      }
      &--warning {
        background: var(--color-secondary);
        cursor: pointer;
        border-right: 1px solid black;
        border-left: 1px solid black;
      }
    }
  }
}

.sl-horizontal-bar-container {
  &--with-axis {
    padding-bottom: 65px;
  }
  .x-axis {
    display: flex;
    padding-left: 20%;
    margin-top: -15px;

    &__tick {
      position: absolute;
      border-left: 1px solid var(--font-destressed-text-color);
      height: 10px;
    }
    &__date {
      position: absolute;
      top: 23px;
    }
    &__day {
      position: absolute;
      color: var(--font-destressed-text-color);
      top: 43px;
    }

    div {
      position: relative;
    }
  }
}
