.sl-login-page {
  width: 100%;
  height: 100%;
  position: absolute;

  &__inner {
    width: 100%;
    max-width: 500px;
  }

  .sl-plume-icon {
    position: absolute;
    bottom: 20px;
    left: 20px;
  }

  #sl-sign-in-button {
    margin: 40px auto 0;
    height: 64px;
  }

  p {
    margin: 0;
  }

  a {
    font-size: 16px;
    line-height: 16px;
    text-decoration: none;
    color: var(--color-page) !important;
  }

  .deployment {
    margin-top: 10px;
    .sl-text {
      color: black !important;
    }
    background: rgba(0,0,0,0.2) !important;
    &:hover {
      background: rgba(0,0,0,0.4) !important;
    }
  }

  .login-panel {
    max-width: 500px;
    border-radius: 20px;
    background: var(--color-panel-background) !important;
    padding: var(--section-padding);
    transform: translateY(20px);
    transition: all 0.3s ease-in;
    opacity: 0;
    &--active {
      opacity: 1;
      transform: translateY(0px);
    }
    h1.sl-text {
      font-size: 32px;
      line-height: 32px !important;
      font-weight: 500;
    }
    .sl-text--subtitle--3 {
      font-size: 20px;
      line-height: 20px;
      font-weight: 400;
    }
  }

  .forgot-link-tooltip {
    max-width: 337px;
    p {
      text-align: left;
    }
    &.show {
      opacity: 1;
    }
  }
}
