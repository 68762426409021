.map-pop-up-click-wrapper {
  height: 0px;
  width: 0px;
  position: absolute;
  color: white;
  .map-pop-up-click {
    background-color: rgb(17, 17, 17);
    line-height: 25px;
    padding-left: 5px;
    position: relative;
    z-index: 50000;
    border-radius: 10px;
    box-shadow: 0px 0px 12px 0px rgba(255, 255, 255, 0.25);
    display: flex;
    flex-direction: column;
    width: max-content;
  }
  .text-to-copy {
    padding-left: 5px;
    position: relative;
    top: 1px;
    display: inline-block;
    .text-link {
      color: var(--color-text);
      text-decoration: none;
    }
    .text-link:hover {
      text-decoration: underline;
    }
  }
  .sl-map-copy-icon {
    cursor: pointer;
    margin-left: 10px;
    width: 25px;
    display: inline-block;
    float: right;
    margin-right: 10px;
    svg {
      z-index: 4000;
      position: relative;
      top: 3px;
    }
  }
}
