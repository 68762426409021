@import "src/style/mixins";

.sl-chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__start {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    & > * {
      margin-right: 8px;
    }
  }

  &__end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    & > * {
      margin-left: 8px;
    }
  }
}
