.sl-admin-alerts-config {
  width: 100%;
  background: #282828;
  border-radius: 15px;
  padding-bottom: 5px;
  padding-top: 20px;
}

.sl-admin-alerts-config {
  padding: var(--spacing-md);
  margin-bottom: var(--spacing-md);
  position: relative;
  .sl-admin-description-toggle {
    margin-bottom: 53px;
    height: 53px;
    .sl-admin-alert-toggle-wrapper {
      display: flex;
      align-items: center;
      position: absolute;
      top: 20px;
      right: 20px;

      &--disabled {
        cursor: not-allowed;
        .slider {
          cursor: not-allowed;
        }
      }
    }
  }
}
