.sl-map {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;

  .sl-overlay {
    position: absolute;
    top: 15px;
    right: 10px;
    z-index: 99;
  }
  &__tooltip {
    padding: 10px;
    position: absolute;
    background: rgba(0, 0, 0, 0.8);
    z-index: 3;
    border-radius: 4px;
    width: 229px;
    margin-left: -114.5px;
    margin-top: 40px;
    opacity: 0;
    transform: translateY(20px);

    p {
      color: var(--font-destressed-text-color);
    }

    p:last-child {
      margin-bottom: 0;
    }

    word-wrap: break-word;

    a:hover {
      text-decoration: underline;
    }

    &--active {
      transition: ease-in-out 0.3s opacity, ease-in-out 0.2s transform;
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__controls-container {
    position: absolute;
    right: 10px;
    bottom: 10px;
    z-index: 2;
  }

  .mapboxgl-ctrl-attrib {
    display: none;
  }
}
