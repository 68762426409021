.sl-selection-list {
  background-color: rgb(0, 0, 0);
  width: 160px;
  border: 1px solid rgba(147, 149, 151, 1);
  border-radius: 12px;
  padding: 20px 0;
  box-shadow: 0px 0px 20px 0px rgba(255, 255, 255, 0.1);

  .sl-header-and-selected-text-wrapper {
    margin-bottom: 25px;
  }

  .sl-header-and-selected-text-wrapper:last-child {
    margin-bottom: 0px;
  }
}
