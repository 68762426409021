.signal-alert {
  all: initial;

  table,
  th,
  thead,
  td,
  tbody,
  tr,
  tr:nth-child(odd),
  tr:nth-child(even) {
    letter-spacing: initial;
    width: initial;
    font-size: initial;
    text-align: initial;
    background-color: initial;
    height: initial;
  }

  .alert-row {
    & > div {
      height: 417px;
    }
  }
  .alert-barchart-outer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 40px 0;
  }

  .alert-dropdown {
  }

  .alert-col-1 {
    flex-basis: 100px;
    .sl-icon {
      width: 20px;
      height: 20px;
      vertical-align: middle;
    }
  }
}
