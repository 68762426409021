.sl-text-and-check-mark-wrapper {
  height: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;

  &:hover {
    background-color: rgba(75, 75, 75, 0.8);
  }

  .sl-text-and-check-mark-checkmark {
    display: inline;
  }

  .sl-icon {
    width: 22px;
    height: 22px;
    margin-left: 10px;
    display: inline-block;
  }
}
