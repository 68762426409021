.signal-monitor {
  background-color: rgb(0, 0, 0);
  color: white;
  margin: 0;

  &-error {
    margin-top: 32px;
  }

  .signal-ham-svg {
    fill: white;
  }
}

.app-mode-light {
  .signal-monitor {
    background-color: rgb(238, 239, 240);
    color: black;
  }

  .signal-ham-svg {
    fill: black;
  }
}

.mon-search-box {
  margin: 20px 0;
  button {
    height: 100%;
    width: 100%;
  }
}
