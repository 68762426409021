.Sl-filter-menu-group-cont {
  height: 0px;
  width: 0px;
  position: relative;

  .Sl-filter-menu-group {
    min-width: 261px;
    max-width: 261px;
    padding-top: 32px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 24px;
    box-shadow: 0px 0px 5px 5px rgba(255, 255, 255, 0.15);
    background: rgba(0, 0, 0, 1) !important;
    z-index: 200000;
    position: absolute;

    .Sl-filter-option-cont {
      margin-bottom: 32px;
    }
  }
}
