.sl-expand-left-wrapper {
  width: 48px;
  height: 48px;
  background-color: rgb(0, 0, 0);
  border-radius: 25px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  svg {
    position: relative;
    right: 5px;
    top: 2px;
  }
}
