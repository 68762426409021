.Sl-LAS-action-search-cont {
  display: flex;
  width: calc(100%);

  .Sl-styled-input-cont {
    flex: 4;
  }
  .Sl-action-button {
    margin-right: 1%;
  }
  .Sl-styled-input-cont {
    margin-right: 1%;
  }
  .Sl-icon-button-cont {
    margin-right: 1%;
  }
  & :last-child {
    margin-right: 0px;
  }
}
.Sl-LAS-action-search-item-paragraph {
  flex: 1 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.sl-alert-details-header {
  padding: 32px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: stretch;

  .Sl-styled-input-cont {
    margin: 8px 40px 8px 8px;
    flex: 1 1 30%;
    min-width: fit-content;
  }

  .sl-button-group {
    .sl-text {
      color: var(--color-solid-grey);
    }
    flex: 0 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    > * {
      margin: 8px 4px;
      flex: 0 0 auto;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
