.signal-chart-legend-cont {
  .signal-chart-trendline-bar {
    width: 16px;
    height: 8px;
    background-color: rgb(250, 20, 120);
    display: inline-block;
    margin-right: 4px;
    position: relative;
    border-radius: 8px;
    line-height: 16px;
  }

  .signal-chart-trendline-bar-title {
    margin-right: 5%;
  }
  .signal-chart-trendline-bar-warning {
    background-color: rgb(147, 149, 151);
  }
  .signal-chart-trendline-bar-good {
    background-color: rgba(233, 233, 233, 0.15);
  }
}
