.signal-ticket {
  .sl-menu {
    .sl-scrollbars {
      margin-bottom: -15px !important;
    }
    .sl-dropdown {
      .sl-scrollbars {
        margin-bottom: 0 !important;
      }
    }
  }

  .alert-row {
    & > div {
      height: 417px;
    }
  }
  .alert-barchart-outer {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .alert-col-1 {
    flex-basis: 100px;
    .sl-icon {
      width: 20px;
      height: 20px;
      vertical-align: middle;
    }
  }

  .ticket-back-arrow {
    margin: 40px 0;
  }
}

.sl-text-red-wrapper {
  display: inline-flex;
  margin-top: 250px;
  width: 100%;
  justify-content: center;
}

.sl-error-page-height {
  height: calc(100vh - 80px);
}
