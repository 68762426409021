@import "src/style/mixins";

.Sl-input-text:not(.Sl-input-text--small) {
  height: 48px;
  max-width: 350px;
  border-radius: 29px;
  .Sl-input-text-input {
    width: 100%;
    display: inline-block;
    height: 48px;
    border-radius: 29px;
    font-size: 18px;
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
    padding-left: 15px;
    border: 0px solid white;

    &:focus {
      outline: none !important;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }
}
.Sl-input-text {
  display: flex;
  align-items: center;
  border: 1px solid black;

  &--valid {
    border-color: rgb(0, 163, 0);
  }
  &--error {
    border-color: #e30000;
  }

  &--small {
    height: 40px;
    width: 100%;
    border: none;
    border-radius: 2px;
    .Sl-input-text-input {
      @include paragraph--7();
      width: 100%;
      height: 100%;
      font-size: 16px;
      background-color: transparent;
      border: none;
      &:focus {
        outline: none !important;
      }
    }
  }
}
