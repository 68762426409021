.Sl-channel-pannel-wrapper {
  width: 100%;

  .Sl-channel-config-elements-wrapper {
    flex: 6;

    .Sl-channel-config-element {
      display: inline-block;
      margin-right: 10px;
    }
  }
}
