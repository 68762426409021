.sl-icon-and-text-button-button {
  background-color: rgba(250, 250, 250, 0.1);
  border-radius: 24px;
  color: white;
  height: 48px;
  line-height: 30px;
  font-size: 18px;
  width: 178px;
  border: 0px solid white;
  cursor: pointer;
  text-align: left;
  padding-left: 20px;
  .sl-icon-and-text-button-icon {
    height: 24px;
    display: inline-flex;
    width: 20px;
    margin-right: 18px;
    svg {
      line-height: 6px;
      display: block;
    }
  }
  .sl-icon-and-text-button-title {
    vertical-align: top;
  }
}
