.sl-dropdown {
  position: relative;

  &:focus {
    outline: none;
  }

  .sl-menu {
    display: none;
    position: absolute;
    top: 66px;
    z-index: -1;
    opacity: 0;
    transition: 0.1s ease-in-out all;
    pointer-events: none;
    transform: translateY(-10px);

    .sl-menu {
      position: fixed;
      left: 230px;
      top: -2px;

      .sl-item {
        pointer-events: none;
      }
    }
  }

  &--align-left .sl-menu {
    left: 0;
  }

  &--align-right .sl-menu {
    right: 0;
  }

  .sl-selection-list {
    visibility: hidden;
    position: absolute;
    top: 58px;
    left: 10px;
    z-index: -1;
    opacity: 0;
    transition: 0.1s ease-in-out all;
    pointer-events: none;
    transform: translateY(-10px);
  }

  &--active {

    > .sl-menu {
      display: block;
      position: absolute;
      top: 66px;
      z-index: 2000;
      opacity: 1;
      transform: translateY(0);
      pointer-events: auto !important;

      .sl-item {
        pointer-events: auto !important;
      }
    }

    .sl-selection-list {
      visibility: visible;
      position: absolute;
      top: 58px;
      left: 10px;
      z-index: 1;
      opacity: 1;
      transform: translateY(0);
      pointer-events: auto;
    }
  }
}
