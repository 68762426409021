.sl-tooltip {
  position: relative;

  .sl-tooltip-title {
    all: unset;
    background: white;
    color: black;
    -webkit-text-fill-color: black;
    padding: 10px;
    display: inline-block;
    font-size: 13px;
    border-radius: 10px;
    font-weight: 300;
    position: absolute;
    transition: all ease-in-out 0.2s;
    pointer-events: none;
    visibility: hidden;
    left: 0;
    top: -40px;
  }

  &--hoverable:hover,
  &--clickable--wastriggered {
    .sl-tooltip-title {
      visibility: visible;
      top: -50px;
    }
  }
}
