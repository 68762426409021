$sl-delete: '.sl-delete';

#{$sl-delete} {
  display: flex;
  justify-content: center;
  align-items: center;
}

#{$sl-delete}-wrapper {
  background-color: rgba(255, 255, 255, 0.4);
  cursor: pointer;
  color: white;
  height: 48px;
  border-radius: 24px;
  display: inline-flex;
  justify-content: center;
  align-content: center;
  overflow: hidden;
  padding: 0;

  #{$sl-delete} {
    position: relative;
    padding: 10px 20px;
    white-space: normal;
    color: white;
    min-height: 24px;

    &--disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    &-item-replacement-label {
      position: absolute;
      color: white;
      height: max-content;
      justify-content: center;
      display: none;
    }

    &:hover {
      #{$sl-delete}-item-replacement-label {
        display: flex;
      }
      #{$sl-delete}-item-label {
        .sl-text {
          color: transparent;
        }
      }
    }

    &--disabled {
      &:hover {
        #{$sl-delete}-item-replacement-label {
          display: none;
        }
      }
    }
  }
}
