.simplebar-scrollbar:before {
  background-color: var(--color-contrast-1);
  opacity: 1 !important;
}

::-webkit-scrollbar {
  background: transparent;
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: rgb(132,132,132);
  border-radius: 10px;
}