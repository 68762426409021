.sl-time-selector-cont {
  display: inline-block;
  cursor: pointer;
  white-space: nowrap;
  .sl-time-selector {
    background-color: rgba(255, 255, 255, 0.05);
    display: flex;
    height: 48px;
    margin: auto;
    border-radius: 25px;

    .sl-time-selection {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
    }
    .sl-time-selection:hover,
    .sl-time-selection--selected {
      background-color: rgba(255, 255, 255, 0.15);
      border-radius: 25px;
    }
  }
}
