.sl-admin-page {

  .sl-section {
    margin: var(--spacing-md) 0;
  }

  .sl-text--subtitle--2m {
    cursor: pointer;
    display: inline;
  }

  &--disabled {
    .sl-section {
      .sl-alert-config-panel {
        cursor: not-allowed;
      }
    }
  }
}
.SL-admin-config-alerts-page {
  color: white;
  margin-top: 100px;
  margin-left: 152px;
  margin-right: 152px;

  .SL-admin-config-alerts-page-title {
    font-size: 24px;
    margin-bottom: 32px;
    width: 100%;
  }
  .SL-admin-config-alerts-alerts-config-container {
    .SL-alert-config-cont {
      margin-bottom: 20px;
    }
  }

  .SL-admin-config-alerts-title-cont {
    width: 100%;

    height: 100px;
    line-height: 100px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 48px;
  }
  .SL-admin-alerts-config-emails {
    width: 100%;
    max-width: 1184px;
    .SL-alert-congig-alerts-header {
      text-transform: capitalize;
      margin-bottom: 20px;
    }
  }
  .SL-admin-email-block {
    margin-bottom: 20px;
  }
}

.signal-admin-error {
  margin-top: 20px;
}
