.sl-animation {
  &--pulse {
    animation: pulse 0.7s infinite ease-in-out alternate;
  }
}

@keyframes pulse {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
