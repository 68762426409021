@import "src/style/mixins";

.sl-button {
  @include font-button-contrast();
  border: 2px solid var(--paragraph--8-color);
  border-radius: 31px;
  background: rgba(255,255,255,0);
  border-color: transparent;
  padding: 12px 24px;
  cursor: pointer;
  box-sizing: border-box;
  transition: background 0.2s linear;

  &.sl-button--dark,
  &.sl-button--dark:hover,
  &.sl-button--dark:focus {
    color: white;
    background: rgba(17,17,17,1) !important;
    border: 2px solid rgba(17,17,17,1);
  }

  &--round {
    border-radius: 100%;
    border: none;
    padding: 10px;
    min-width: 48px;
    min-height: 48px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: inherit;
  }

  &:not(:disabled):hover,
  &:not(:disabled):focus {
    background: rgba(255,255,255,0.1);
  }

  &--background-opacity-0p1,
  &--background-opacity-highlighted-0p1:not(:disabled):hover,
  &--background-opacity-highlighted-0p1:not(:disabled):focus {
    background: rgba(255,255,255,0.1);
  }

  &--background-opacity-0p2,
  &--background-opacity-highlighted-0p2:not(:disabled):hover,
  &--background-opacity-highlighted-0p2:not(:disabled):focus {
    background: rgba(255,255,255,0.2);
  }

  .sl-text {
    text-align: center;
  }

  > * {
    margin: 0 4px;
  }

  > *:first-child {
    margin-left: 0;
  }

  > *:last-child {
    margin-right: 0;
  }
}
