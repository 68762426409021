.signal-chart-legend-cont {
  width: 100%;
  height: 20px;
  margin-top: 10px;
  margin-bottom: 30px;
  .signal-chart-legend {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
