.signal-monitoring-kpi-square-cont {
  display: flex;
  flex-wrap: wrap;

  .signal-monitoring-kpi-square {
    height: 544px;
    width: calc(50% - 5px);
    background-color: rgba(255, 255, 255, 0.08);
    margin-bottom: 10px;
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 45px;

    .sl-selection-list {
      display: flex;
      flex-direction: column-reverse;

      .sl-header-and-selected-text-wrapper {
        margin-bottom: 0;

        &:last-child {
          margin-bottom: 25px;
        }
      }
    }
  }
  .marg-right-kpi {
    margin-right: 10px;
  }

  .signal-monitoring-kpi-time-more {
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  .Sl-err-ref {
    color: #e60023;
  }

  .Sl-monitor-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

}

.kpi-cont {
  .section-header-tooltip {
    width: 337px;
  }
}
