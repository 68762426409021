@mixin adjust-controls-in-expanded-view() {
  &--expanded {
    left: calc(100% - 78px);
  }
}

.monitor-map-all {
  height: calc(100vh - 300px);
  width: calc(100% - 96px);
  margin-left: 48px;
  margin-right: 48px;
  border: 1px solid grey;
  margin-bottom: 44px;
  min-height: 616px;

  .Sl-monitor-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 300px);
  }

  .Sl-err-ref {
    color: #e60023;
  }

  .monitor-dash {
    height: 100%;
    width: 100%;
  }

  .sl-zoom-in-out-home-wrapper {
    position: relative;
    z-index: 5000;
    height: 0px;
    width: 0px;
    display: block;
    right: 10px;
    bottom: 0px;
    top: calc(100% - 200px);
    left: calc(100% - 58px);

    @include adjust-controls-in-expanded-view;

  }

  .sl-title-time-update-wrapper-relative {
    position: relative;
    z-index: 1000;
    height: 0px;
    width: 0px;
    display: block;
    right: 10px;
    bottom: 0px;
    top: 10px;
    left: calc(100% - 400px);
  }

  .sl-expand-map-wrapper-wrapper {
    position: relative;
    z-index: 1000;
    height: 0px;
    width: 0px;
    display: block;
    right: 40px;
    bottom: 0px;
    top: calc(100% - 260px);
    left: calc(100% - 58px);

    @include adjust-controls-in-expanded-view;
  }
  .sl-expand-left-wrapper-wrapper {
    position: relative;
    z-index: 1000;
    height: 0px;
    width: 0px;
    display: block;
    right: 10px;
    bottom: 0px;
    top: 10px;
    left: calc(100% - 100px);
  }
}
