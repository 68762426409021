$tile-value-indicator: '.TileValueIndicator';

#{$tile-value-indicator} {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__text {
    margin-top: 4px;
    margin-bottom: 4px;
    text-align: center;
  }

  &__subtitle {
    width: 208px;
  }

  &__circle-value {
    margin: 0;
    font-weight: 500;
    padding-top: 3px;
  }
}
