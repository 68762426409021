.sl-select-text-number-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 48px;
  font-size: 16px;
  line-height: 22px;
  padding-left: 23px;
  &:hover {
    cursor: pointer;
    background-color: rgb(50, 50, 50);
    height: 48px;
  }
  .sl-selected-text-wrapper {
    display: inline-block;
    width: 220px;
  }
  .sl-number {
    display: inline-block;
    margin-left: 45px;
  }
}
