.sl-range-slider {
  display: flex;
  flex-direction: row;

  &-pill {
    width: 100%;
    display: flex;
    padding: 15px 20px 12px 20px;
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 25px;
  }

  &-label {
    flex: 1;

    &--time {
      min-width: 200px;
    }
  }

  &-controls {
    button:disabled {
      p {
        color: var(--color-solid-secondary);
      }
    }
  }
}
