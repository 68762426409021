.sl-expand-map-wrapper {
  height: 48px;
  width: 48px;
  background: rgb(0, 0, 0);
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
