.sl-delete-list {
  display: flex;
  flex-wrap: wrap;
  .sl-delete-wrapper {
    margin-right: 16px;
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }
}
