:root {
  /************************************
  Colors
  ************************************/
  --color-page: #111111;
  --color-text: #ffffff;
  --color-contrast-6: #1d1d1d;
  --color-contrast-5: #262626;
  --color-contrast-4: #383838;
  --color-contrast-3: #454545;
  --color-contrast-2p5: #4d4d4d;
  --color-contrast-2: #505050;
  --color-contrast-1: #7f7f7f;
  --color-contrast-0: #aeaeae;
  --color-destressed: #c6c6c6;
  --color-primary: #fa1478;
  --color-secondary: #6269ff;
  --color-tertiary: #ff9900;
  --color-action-primary: #17e3ae;
  --color-action-secondary: #dedede;
  --color-action-tertiary: #2798ae;
  --color-regular: #282b2c;
  --color-subdued: #919191;
  --color-danger: #f75757;
  --font-paragraph-danger-color: #fa1478;
  --color-positive: #a9fbc6;
  --color-secondary-info: #ff9900;
  --color-header-text: #2798ae;
  --color-danger: #f75757;
  --color-solid-secondary: #8F9397;
  --color-solid-grey: #DEE0E2;
  --color-panel-background: #fff;
  --color-panel-paragraph: #111;
  --is-active: rgba(65, 65, 65, 0.45);

  /************************************
  Default Typopgraphy
  ************************************/
  --font-default-line-height: normal;
  --font-default-text-align: left;
  --font-default-color: var(--color-text);
  --font-default-destressed-color: var(--color-destressed);
  --font-default-primary-color: var(--color-primary);
  --font-default-positive-color: var(--color-positive);
  --font-default-font-style: normal;
  --font-default-font-weight: normal;
  --font-default-font-family: wigrumweb;
  --font-default-text-size: 16px;

  /************************************
  Spacing
  ************************************/
  --spacing-xs: 20px;
  --spacing-sm: 30px;
  --spacing-md: 48px;
  --spacing-lg: 56px;
  --spacing-xl: 71px;
  --spacing-xxl: 81px;
  --spacing-xxxl: 100px;

  /************************************
  Metric Value
  ************************************/
  --font-metric-value-font-size: 48px;
  --font-metric-value-line-height: var(--font-default-line-height);
  --font-metric-value-text-align: var(--font-default-text-align);
  --font-metric-value-color: var(--font-default-color);
  --font-metric-value-font-style: var(--font-default-font-style);
  --font-metric-value-font-weight: 500;
  --font-metric-value-font-family: var(--font-default-font-family);

  /************************************
  Title_Signal
  ************************************/
  --title--signal-font-family: ocrey;
  --title--signal-font-size: 36px;
  --title--signal-line-height: 36px;
  --title--signal-font-weight: 400;
  --title--signal-font-style: normal;
  --title--signal-color: var(--font-default-color);
  --title--signal-text-align: var(--font-default-text-align);

  /************************************
  Title_M (medium weight)
  ************************************/
  --title--m-font-family: wigrumweb;
  --title--m-font-size: 32px;
  --title--m-line-height: 32px;
  --title--m-font-weight: 500;
  --title--m-font-style: normal;
  --title--m-color: var(--font-default-color);
  --title--m-text-align: var(--font-default-text-align);

  /************************************
  Heading_3M (style #3, medium weight)
  ************************************/
  --heading--3m-font-family: wigrumweb;
  --heading--3m-font-size: 48px;
  --heading--3m-line-height: 48px;
  --heading--3m-font-weight: 500;
  --heading--3m-font-style: normal;
  --heading--3m-color: var(--font-default-color);
  --heading--3m-text-align: var(--font-default-text-align);

  /************************************
  Subtitle_1M (style #1, medium weight)
  ************************************/
  --subtitle--1m-font-family: wigrumweb;
  --subtitle--1m-font-size: 28px;
  --subtitle--1m-line-height: 28px;
  --subtitle--1m-font-weight: 500;
  --subtitle--1m-font-style: normal;
  --subtitle--1m-color: var(--font-default-color);
  --subtitle--1m-text-align: var(--font-default-text-align);

  /************************************
  Subtitle_2M (style #2, medium weight)
  ************************************/
  --subtitle--2m-font-family: wigrumweb;
  --subtitle--2m-font-size: 24px;
  --subtitle--2m-line-height: 24px;
  --subtitle--2m-font-weight: 500;
  --subtitle--2m-font-style: normal;
  --subtitle--2m-color: var(--font-default-color);
  --subtitle--2m-text-align: var(--font-default-text-align);

  /************************************
  Subtitle_3 (style #3)
  ************************************/
  --subtitle--3-font-family: wigrumweb;
  --subtitle--3-font-size: 20px;
  --subtitle--3-line-height: 20px;
  --subtitle--3-font-weight: 400;
  --subtitle--3-font-style: normal;
  --subtitle--3-color: var(--font-default-color);
  --subtitle--3-text-align: var(--font-default-text-align);

  /************************************
  Subtitle_4 (style #4)
  ************************************/
  --subtitle--4-font-family: wigrumweb;
  --subtitle--4-font-size: 16px;
  --subtitle--4-line-height: 16px;
  --subtitle--4-font-weight: 400;
  --subtitle--4-font-style: normal;
  --subtitle--4-color: var(--font-default-color);
  --subtitle--4-text-align: var(--font-default-text-align);

  /************************************
  Subtitle_4M (style #4, medium weight)
  ************************************/
  --subtitle--4m-font-family: wigrumweb;
  --subtitle--4m-font-size: 16px;
  --subtitle--4m-line-height: 16px;
  --subtitle--4m-font-weight: 500;
  --subtitle--4m-font-style: normal;
  --subtitle--4m-color: var(--font-default-color);
  --subtitle--4m-text-align: var(--font-default-text-align);

  /************************************
  Subtitle_6 (style #6)
  ************************************/
  --subtitle--6-font-family: wigrumweb;
  --subtitle--6-font-size: 10px;
  --subtitle--6-line-height: 10px;
  --subtitle--6-font-weight: 400;
  --subtitle--6-font-style: normal;
  --subtitle--6-color: var(--font-default-color);
  --subtitle--6-text-align: var(--font-default-text-align);

  /************************************
  Paragraph_7 (style #7)
  ************************************/
  --paragraph--7-font-family: wigrumweb;
  --paragraph--7-font-size: 16px;
  --paragraph--7-line-height: 22px;
  --paragraph--7-font-weight: 400;
  --paragraph--7-font-style: normal;
  --paragraph--7-color: var(--font-default-color);
  --paragraph--7-destressed-color: var(--font-default-destressed-color);
  --paragraph--7-primary-color: var(--font-default-primary-color);
  --paragraph--7-positive-color: var(--font-default-positive-color);
  --paragraph--7-text-align: var(--font-default-text-align);

  /************************************
  Paragraph_8 (style #8)
  ************************************/
  --paragraph--8-font-family: wigrumweb;
  --paragraph--8-font-size: 12px;
  --paragraph--8-line-height: 16px;
  --paragraph--8-font-weight: 400;
  --paragraph--8-font-style: normal;
  --paragraph--8-color: var(--font-default-color);
  --paragraph--8-destressed-color: var(--font-default-destressed-color);
  --paragraph--8-text-align: var(--font-default-text-align);

  /************************************
  Destressed Text
  ************************************/
  --font-destressed-text-font-size: 16px;
  --font-destressed-text-line-height: var(--font-default-line-height);
  --font-destressed-text-text-align: var(--font-default-text-align);
  --font-destressed-text-color: var(--color-destressed);
  --font-destressed-text-font-style: var(--font-default-font-style);
  --font-destressed-text-font-weight: 400;
  --font-destressed-text-font-family: var(--font-default-font-family);

  /************************************
  Input Value
  ************************************/
  --font-input-value-font-size: 18px;
  --font-input-value-line-height: 26px;
  --font-input-value-text-align: var(--font-default-text-align);
  --font-input-value-color: #303030;
  --font-input-value-font-style: var(--font-default-font-style);
  --font-input-value-font-weight: 400;
  --font-input-value-font-family: var(--font-default-font-family);

  /************************************
  Input Placeholder
  ************************************/
  --font-input-placeholder-font-size: 18px;
  --font-input-placeholder-line-height: var(--font-default-line-height);
  --font-input-placeholder-text-align: var(--font-default-text-align);
  --font-input-placeholder-color: #aeaeae;
  --font-input-placeholder-font-style: var(--font-default-font-style);
  --font-input-placeholder-font-weight: 400;
  --font-input-placeholder-font-family: var(--font-default-font-family);

  /************************************
  Button Contrast
  ************************************/
  --font-button-contrast-font-size: 18px;
  --font-button-contrast-line-height: var(--font-default-line-height);
  --font-button-contrast-text-align: center;
  --font-button-contrast-color: #ffffff;
  --font-button-contrast-font-style: var(--font-default-font-style);
  --font-button-contrast-font-weight: var(--font-default-font-weight);
  --font-button-contrast-font-family: var(--font-default-font-family);

  /************************************
  Header Text
  ************************************/
  --font-header-text-font-size: 16px;
  --font-header-text-line-height: var(--font-default-line-height);
  --font-header-text-text-align: center;
  --font-header-text-color: #ffffff;
  --font-header-text-font-style: var(--font-default-font-style);
  --font-header-text-font-weight: bold;
  --font-header-text-font-family: var(--font-default-font-family);

  /************************************
  Hyperlink
  ************************************/
  --font-hyperlink-font-size: 14px;
  --font-hyperlink-line-height: var(--font-default-line-height);
  --font-hyperlink-text-align: var(--font-default-text-align);
  --font-hyperlink-color: var(--color-text);
  --font-hyperlink-text-decoration: underline;
  --font-hyperlink-font-style: var(--font-default-font-style);
  --font-hyperlink-font-weight: 600;
  --font-hyperlink-font-family: var(--font-default-font-family);

  /************************************
  Header
  ************************************/
  --font-header-font-size: 32px;
  --font-header-line-height: var(--font-default-line-height);
  --font-header-text-align: var(--font-default-text-align);
  --font-header-color: #282b2c;
  --font-header-font-style: var(--font-default-font-style);
  --font-header-font-weight: 500;
  --font-header-font-family: var(--font-default-font-family);

  /************************************
  Page
  ************************************/
  --section-maxwidth: 1346px;
  --section-padding: 45px 60px;

  /************************************
  Leagacy
  ************************************/
  --textPrimary: #3c396f;
  --textSecondary: #939597;
  --grayInactive: #dee0e3;
  --grayLight: #f2f3f5;
  --textSecondaryFaded: color(#939597 a(50%));
  --primaryBlue: #6269ff;
  --primaryBlueFaded: #638bdf;
  --primaryPurple: #803fce;
  --white: #fff;
  --primaryTallow: #b99948;
  --primaryGray: #e4e4e4;
  --backgroundGray: #fafafc;
  --borderColor: rgba(222, 222, 222, 0.67);
  --backgroundPampas: #f9f9f5;
  --backgroundTallow: #a59e86;
  --errorRed: #fa1478;
  --sucessGreen: #16e2a8;
  --enableGreen: #74bbfc;
  --disableRed: #8b2323;
  --warningRed: #bf2468;
}
