.sl-admin-description-toggle {
  width: 100%;
  .sl-admin-description-toggle-description {
    position: relative;
    top: 30px;
  }

  .sl-admin-alert-toggle-wrapper {
  }
}
