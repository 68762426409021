.sl-plume-menu-opener-wrapper {
  &:hover {
    background-color: rgba(37, 41, 46, 0.95);
  }
}

.sl-plume-menu-opener-wrapper {
  height: 56px;
  width: 241px;
  border-radius: 59px;
  cursor: pointer;
  color: white;
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0.1);

  .sl-plume-text-button-x {
    height: 100%;
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .sl-plume-text-button-text-icon {
    height: 100%;
    width: 100%;
    line-height: 56px;
    margin-left: 20px;
    position: relative;
    top: 1px;

    .sl-plume-text-button-icon {
      margin-right: 25px;
      vertical-align: sub;
      width: 25px;
      display: inline-block;
      position: relative;
      top: 1px;
    }
  }
}
