.sl-input {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  padding: 0 5px;
  position: relative;

  &--focus {
    border-bottom: 2px solid var(--color-contrast-3);
  }

  &--unfocus {
    border-bottom: 2px solid var(--color-contrast-0);
  }

  &__end {
    color: var(--color-destressed);
    font-size: 13px;
    position: absolute;
    right: 10px;
    top: 0;
    a,
    a:visited,
    a:focus {
      color: var(--color-destressed);
    }
    a:hover {
      color: var(--color-contrast-3);
    }
  }

  label {
    color: var(--font-destressed-text-color);
    cursor: pointer;
    padding: 0 5px;
  }

  input {
    border: none;
    padding: 10px 5px;
    font-size: 16px;
    display: block;
    width: 100%;
    outline: none;
  }

  .signal-admin-input-valid {
    border: 2px solid var(--color-positive);
  }

  .sl-icon {
    position: absolute;
    top: 23px;
    right: 10px;
    opacity: 0.5;
    width: 21px;
  }
}
