.switch {
  display: inline-block;
  height: 14px;
  position: relative;
  width: 34px;
}

.switch input {
  display: none;
}

.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.slider:before {
  background-color: #fff;
  bottom: -3px;
  content: "";
  height: 20px;
  left: 0px;
  position: absolute;
  transition: 0.4s;
  width: 20px;
}

input:checked + .slider {
  background-color: rgba(98, 105, 255, 0.75);
}

input:checked + .slider:before {
  transform: translateX(14px);
  background-color: #6269ff;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
  background-color: #dee0e2;
}
