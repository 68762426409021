.solve-dash-err {
  color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  .solve-data-loading-error {
    width: 450px;
    text-align: center;
    border: 1px solid rgba(250, 250, 250, 0.25);
    padding: 10px;
    border-radius: 15px;
  }
}
