@import "src/style/mixins.scss";

.sl-alert-data {

  .alarm-text {
    color: var(--color-primary);
  }

  .sl-section {
    margin-top: var(--spacing-lg);
    padding: 0;
  }

  &__sub-header {
    padding-left: var(--spacing-xxxl);
    margin-bottom: var(--spacing-md);
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: var(--spacing-xxxl);
    margin: var(--spacing-lg) 0 var(--spacing-md) 0;
    font-size: 24px;
    position: relative;

    &.active .sl-icon {
      transform: rotate(180deg);
    }

    &.has-tickets {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
      .sl-icon {
        opacity: 1;
        pointer-events: inherit;
        &:hover {
          background: transparent;
        }
      }
      .sl-round-button {
        pointer-events: inherit;
      }
    }

    .sl-button {
      margin-right: 20px;
    }

    .sl-icon {
      transform: rotate(0);
      transition: transform linear 0.1s;
      opacity: 0.5;
    }
  }

  .section-header-tooltip {
    width: 337px;
  }
}

.alert-barchart {
  display: flex;
  flex-flow: column;

  @include responsive-min-md {
    padding: 30px 0 0 40px;
  }
  height: 417px;
  &__header {
    display: flex;
    align-items: center;
    h3,
    button {
      display: block;
    }
    h3 {
      margin: 0;
    }
    button {
      margin-left: 10px;
    }
  }
  &__inner {
    flex: 1;
  }
}
