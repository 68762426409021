@import "src/style/design-tokens";

.sl-topmost-section {
  position: relative;
  margin: var(--spacing-xxl) 0 var(--spacing-lg) 0;

  .sl-dropdown {
    position: absolute;
    top: -10px;
    left: 26px;
  }

  .sl-metric:last-child {
    margin-bottom: 0;
  }
  &__inner {
    .sl-metric {
      padding-left: 104px;
    }
  }
}
