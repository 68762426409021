.sl-plume-text-button-wraper {
  height: 56px;
  width: 241px;
  border-radius: 59px;
  cursor: pointer;
  color: white;
  font-size: 16px;
  margin-top: 16px;

  .sl-plume-text-button-x {
    height: 100%;
    width: 100%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    svg {
      position: relative;
    }
  }
  .sl-plume-text-button-text-icon {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    line-height: 22px;
    margin-left: 20px;

    .sl-plume-text-button-icon {
      margin-right: 25px;
      vertical-align: baseline;
      position: relative;
      top: 2px;
    }
    .sl-plume-text-button-text {
      position: relative;
      top: 2px;
    }
  }
}

.sl-hover-light-grey {
  &:hover {
    background-color: lightgray !important;
  }
}
