@import "src/style/mixins.scss";

.sl-section {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  box-sizing: border-box;

  &__inner {
    max-width: 1248px;
    margin: auto;
    position: relative;
    padding: 0 var(--spacing-xs);

    @include responsive-min-sm {
      padding: 0 var(--spacing-md);
    }
  }

  &--contrast-0 {
    background: var(--color-contrast-0);
  }
  &--contrast-1 {
    background: var(--color-contrast-1);
  }
  &--contrast-2 {
    background: var(--color-contrast-2);
  }
  &--contrast-3 {
    background: var(--color-contrast-3);
  }
  &--contrast-4 {
    background: var(--color-contrast-4);
  }
  &--contrast-5 {
    background: var(--color-contrast-5);
  }
  &--contrast-6 {
    background: var(--color-contrast-6);
  }
}
