@import "src/style/mixins";

.sl-text {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &--title {
    &--signal {
      @include title--signal();
    }
    &--1m {
      @include title--m();
    }
  }

  &--heading {
    &--3m {
      @include heading--3m();
    }
  }

  &--subtitle {
    &--1m {
      @include --subtitle--1m();
    }
    &--2m {
      @include --subtitle--2m();
    }
    &--4 {
      @include --subtitle--4();
    }
    &--4m {
      @include --subtitle--4m();
    }
  }

  &--paragraph {
    &--7 {
      @include paragraph--7();
    }
    &--7-destressed {
      @include paragraph--7-destressed();
    }
    &--7-primary {
      @include paragraph--7-primary();
    }
    &--7-positive {
      @include paragraph--7-positive();
    }
    &--8 {
      @include paragraph--8();
    }
    &--8-destressed {
      @include paragraph--8-destressed();
    }
  }
}
