.sl-icon {
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .with-color svg * {
    fill: inherit;
  }
}

button.sl-icon {
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
}
