.sl-alert-ticket-table {
  background: var(--color-contrast-5);
  margin-bottom: var(--spacing-xs);

  &:last-child {
    margin-bottom: 0;
  }

  .sl-text--subtitle--2m {
    padding-top: var(--spacing-lg);
    padding-left: var(--spacing-xs);
    margin-bottom: var(--spacing-sm);

    @media screen and (min-width: 992px) {
      padding-left: var(--spacing-xxxl);
    }
  }

  .sl-chip::first-letter {
    text-transform: uppercase;
  }

  table {
    width: 100%;
    line-height: 1.4;
    border-spacing: 0;
    .archive-icon {
      margin-right: 20px;
    }
    tr {
      td {
        padding: var(--spacing-xs) 20px;
        &.ticket-status {
          vertical-align: top;
          .sl-text {
            padding: 0;
            margin: 0;
          }
        }

        &.ticket-details {
          vertical-align: top;
          .sl-text:first-child {
            margin-bottom: 20px;
          }
        }

        &.ticket-actions {
          .sl-text {
            margin: 0;
          }
        }
      }
      td:nth-child(1) {
        width: 100px;
        @media screen and (min-width: 992px) {
          padding-left: var(--spacing-xxxl);
        }
      }
      td:nth-child(3) {
        width: 200px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        text-align: right;
        @media screen and (min-width: 992px) {
          width: 300px;
          padding-right: var(--spacing-xxxl);
        }
      }
    }
    tbody tr {
      transition: background linear 0.2s;

      .sl-round-button,
      .button-container {
        display: inline-block;
      }

      .sl-round-button {
        width: 40px;
        height: 40px;
      }
      .sl-round-button,
      .sl-button {
        opacity: 0;
      }
      &:hover {
        background: var(--color-contrast-4);
        cursor: pointer;
        .sl-round-button,
        .sl-button {
          opacity: 1;
        }
      }
    }
  }

  &__action-bar {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .button-row-status {
      pointer-events: none;
      opacity: 1;
      .sl-text {
        margin: -5px;
        padding: 0;
      }
      &--hidden {
        transition: opacity ease-in 2s;
        opacity: 0;
      }
    }

    & > * {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    .sl-round-button:last-of-type {
      margin-right: 10px;
    }
  }

}
