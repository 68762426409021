@mixin title--signal() {
  font-family: var(--title--signal-font-family);
  font-size: var(--title--signal-font-size);
  line-height: var(--title--signal-line-height);
  font-weight: var(--title--signal-font-weight);
  font-style: var(--title--signal-font-style);
  color: var(--title--signal-color);
  text-align: var(--title--signal-text-align);
}

@mixin title--m() {
  font-family: var(--title--m-font-family);
  font-size: var(--title--m-font-size);
  line-height: var(--title--m-line-height);
  font-weight: var(--title--m-font-weight);
  font-style: var(--title--m-font-style);
  color: var(--title--m-color);
  text-align: var(--title--m-text-align);
}

@mixin heading--3m() {
  font-family: var(--heading--3m-font-family);
  font-size: var(--heading--3m-font-size);
  line-height: var(--heading--3m-line-height);
  font-weight: var(--heading--3m-font-weight);
  font-style: var(--heading--3m-font-style);
  color: var(--heading--3m-color);
  text-align: var(--heading--3m-text-align);
}

@mixin --subtitle--1m() {
  font-family: var(--subtitle--1m-font-family);
  font-size: var(--subtitle--1m-font-size);
  line-height: var(--subtitle--1m-line-height);
  font-weight: var(--subtitle--1m-font-weight);
  font-style: var(--subtitle--1m-font-style);
  color: var(--subtitle--1m-color);
  text-align: var(--subtitle--1m-text-align);
}

@mixin --subtitle--2m() {
  font-family: var(--subtitle--2m-font-family);
  font-size: var(--subtitle--2m-font-size);
  line-height: var(--subtitle--2m-line-height);
  font-weight: var(--subtitle--2m-font-weight);
  font-style: var(--subtitle--2m-font-style);
  color: var(--subtitle--2m-color);
  text-align: var(--subtitle--2m-text-align);
}

@mixin --subtitle--3() {
  font-family: var(--subtitle--3-font-family);
  font-size: var(--subtitle--3-font-size);
  line-height: var(--subtitle--3-line-height);
  font-weight: var(--subtitle--3-font-weight);
  font-style: var(--subtitle--3-font-style);
  color: var(--subtitle--3-color);
  text-align: var(--subtitle--3-text-align);
}

@mixin --subtitle--4() {
  font-family: var(--subtitle--4-font-family);
  font-size: var(--subtitle--4-font-size);
  line-height: var(--subtitle--4-line-height);
  font-weight: var(--subtitle--4-font-weight);
  font-style: var(--subtitle--4-font-style);
  color: var(--subtitle--4-color);
  text-align: var(--subtitle--4-text-align);
}

@mixin --subtitle--4m() {
  font-family: var(--subtitle--4m-font-family);
  font-size: var(--subtitle--4m-font-size);
  line-height: var(--subtitle--4m-line-height);
  font-weight: var(--subtitle--4m-font-weight);
  font-style: var(--subtitle--4m-font-style);
  color: var(--subtitle--4m-color);
  text-align: var(--subtitle--4m-text-align);
}

@mixin --subtitle--6() {
  font-family: var(--subtitle--6-font-family);
  font-size: var(--subtitle--6-font-size);
  line-height: var(--subtitle--6-line-height);
  font-weight: var(--subtitle--6-font-weight);
  font-style: var(--subtitle--6-font-style);
  color: var(--subtitle--6-color);
  text-align: var(--subtitle--6-text-align);
}

@mixin paragraph--7() {
  font-family: var(--paragraph--7-font-family);
  font-size: var(--paragraph--7-font-size);
  line-height: var(--paragraph--7-line-height);
  font-weight: var(--paragraph--7-font-weight);
  font-style: var(--paragraph--7-font-style);
  color: var(--paragraph--7-color);
  text-align: var(--paragraph--7-text-align);
}

@mixin paragraph--7-destressed() {
  font-family: var(--paragraph--7-font-family);
  font-size: var(--paragraph--7-font-size);
  line-height: var(--paragraph--7-line-height);
  font-weight: var(--paragraph--7-font-weight);
  font-style: var(--paragraph--7-font-style);
  color: var(--paragraph--7-destressed-color);
  text-align: var(--paragraph--7-text-align);
}

@mixin paragraph--7-primary() {
  font-family: var(--paragraph--7-font-family);
  font-size: var(--paragraph--7-font-size);
  line-height: var(--paragraph--7-line-height);
  font-weight: var(--paragraph--7-font-weight);
  font-style: var(--paragraph--7-font-style);
  color: var(--paragraph--7-primary-color);
  text-align: var(--paragraph--7-text-align);
}

@mixin paragraph--7-positive() {
  font-family: var(--paragraph--7-font-family);
  font-size: var(--paragraph--7-font-size);
  line-height: var(--paragraph--7-line-height);
  font-weight: var(--paragraph--7-font-weight);
  font-style: var(--paragraph--7-font-style);
  color: var(--paragraph--7-positive-color);
  text-align: var(--paragraph--7-text-align);
}

@mixin paragraph--8() {
  font-family: var(--paragraph--8-font-family);
  font-size: var(--paragraph--8-font-size);
  line-height: var(--paragraph--8-line-height);
  font-weight: var(--paragraph--8-font-weight);
  font-style: var(--paragraph--8-font-style);
  color: var(--paragraph--8-color);
  text-align: var(--paragraph--8-text-align);
}

@mixin paragraph--8-destressed() {
  font-family: var(--paragraph--8-font-family);
  font-size: var(--paragraph--8-font-size);
  line-height: var(--paragraph--8-line-height);
  font-weight: var(--paragraph--8-font-weight);
  font-style: var(--paragraph--8-font-style);
  color: var(--paragraph--8-destressed-color);
  text-align: var(--paragraph--8-text-align);
}

@mixin font-metric-value() {
  font-family: var(--font-metric-value-font-family);
  font-size: var(--font-metric-value-font-size);
  line-height: var(--font-metric-value-line-height);
  font-weight: var(--font-metric-value-font-weight);
  font-style: var(--font-metric-value-font-style);
  color: var(--font-metric-value-color);
  text-align: var(--font-metric-value-text-align);
}

@mixin font-paragraph-danger() {
  font-family: var(--paragraph--8-font-family);
  font-size: var(--paragraph--8-font-size);
  line-height: var(--paragraph--8-line-height);
  font-weight: var(--paragraph--8-font-weight);
  font-style: var(--paragraph--8-font-style);
  color: var(--font-paragraph-danger-color);
  text-align: var(--paragraph--8-text-align);
}

@mixin font-destressed-text() {
  font-family: var(--font-destressed-text-font-family);
  font-size: var(--font-destressed-text-font-size);
  line-height: var(--font-destressed-text-line-height);
  font-weight: var(--font-destressed-text-font-weight);
  font-style: var(--font-destressed-text-font-style);
  color: var(--font-destressed-text-color);
  text-align: var(--font-destressed-text-text-align);
}

@mixin font-input-value() {
  font-family: var(--font-input-value-font-family);
  font-size: var(--font-input-value-font-size);
  line-height: var(--font-input-value-line-height);
  font-weight: var(--font-input-value-font-weight);
  font-style: var(--font-input-value-font-style);
  color: var(--font-input-value-color);
  text-align: var(--font-input-value-text-align);
}

@mixin font-input-placeholder() {
  font-family: var(--font-input-placeholder-font-family);
  font-size: var(--font-input-placeholder-font-size);
  line-height: var(--font-input-placeholder-line-height);
  font-weight: var(--font-input-placeholder-font-weight);
  font-style: var(--font-input-placeholder-font-style);
  color: var(--font-input-placeholder-color);
  text-align: var(--font-input-placeholder-text-align);
}

@mixin font-button-contrast() {
  font-family: var(--font-button-contrast-font-family);
  font-size: var(--font-button-contrast-font-size);
  line-height: var(--font-button-contrast-line-height);
  font-weight: var(--font-button-contrast-font-weight);
  font-style: var(--font-button-contrast-font-style);
  color: var(--font-button-contrast-color);
  text-align: var(--font-button-contrast-text-align);
}

@mixin font-header-text() {
  font-family: var(--font-header-text-font-family);
  font-size: var(--font-header-text-font-size);
  line-height: var(--font-header-text-line-height);
  font-weight: var(--font-header-text-font-weight);
  font-style: var(--font-header-text-font-style);
  color: var(--font-header-text-color);
  text-align: var(--font-header-text-text-align);
}

@mixin font-hyperlink() {
  font-family: var(--font-hyperlink-font-family);
  font-size: var(--font-hyperlink-font-size);
  line-height: var(--font-hyperlink-line-height);
  font-weight: var(--font-hyperlink-font-weight);
  font-style: var(--font-hyperlink-font-style);
  text-decoration: var(--font-hyperlink-text-decoration);
  color: var(--font-hyperlink-color);
  text-align: var(--font-hyperlink-text-align);
}

/************************************
Media Queries
************************************/

@mixin responsive-min-sm {
  @media (min-width: 768px) {
    @content;
  }
}
@mixin responsive-min-md {
  @media (min-width: 992px) {
    @content;
  }
}
@mixin responsive-min-lg {
  @media (min-width: 1200px) {
    @content;
  }
}
