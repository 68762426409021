@import "src/style/mixins.scss";

.sl-metric {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: var(--spacing-lg);
  overflow: auto;

  .tooltip {
    max-width: 337px;
  }

  &__icon {
    margin-right: var(--spacing-sm);
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__value {
    display: flex;
    flex-direction: row;
    align-items: center;
    .sl-metric__tooltip {
      margin-left: 26px;
    }
  }

  .sl-metric__inner {
    .sl-metric__value {
      margin-bottom: 4px;
    }
    .sl-text {
      margin: 0;
      padding: 0;
    }
  }
}
