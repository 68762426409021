.selection-cont {
  width: 200px;
  display: inline-block;
  margin-right: 25px;
  select {
    width: 100%;
    background-color: rgba(26, 26, 64, 0.25);
    color: white;
    border: 1px solid darkgray;
    font-size: 12px;
  }
}