.signal-trendline {
  height: 60%;
  //min-height: 300px;
  width: 100%;
  .Sl-trendline-popover-cont {
    position: relative;
    height: 0px;
    width: 0px;
    pointer-events: none;
    .Sl-trendline-popover {
      position: absolute;
      background: rgba(250, 250, 250, 0.85);
      box-shadow: 0 1px 3px rgba(255, 255, 255, 0.1), 0 1px 1px;
      height: 24px;
      width: 105px;
      color: black;
      font-size: 12px;
      border-radius: 2px;
      line-height: 24px;
      padding: 4px;

      padding-top: 2px;
      padding-bottom: 2px;
      text-align: center;
      .Sl-trendline-popover-value {
        font-weight: bold;
      }
    }
  }
}
