.sl-alert-location-alarm-score {
  .Sl-LAS-action-search-cont {
    margin-top: 32px;
  }
  .Sl-pag-controls-cont {
    margin-top: 40px;
  }
  .sl-table-footer {
    margin-top: 13px;
  }
}
