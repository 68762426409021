@import "src/style/mixins";

@font-face {
  font-family: wigrumweb;
  font-weight: 400;
  src: url("../assets/fonts/wigrumweb-regular.woff") format("woff");
}

@font-face {
  font-family: wigrumweb;
  font-weight: 500;
  src: url("../assets/fonts/wigrumweb-medium.woff") format("woff");
}

@font-face {
  font-family: wigrumweb;
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/wigrumweb-bold.woff") format("woff");
}

@font-face {
  font-family: ocrey;
  font-weight: 300;
  src: url("../assets/fonts/OC-Rey-Regular.otf") format("otf");
}
