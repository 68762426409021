.monitor-map-layers-rel {
  position: relative;
  height: 0px;
  width: 0px;
  background-color: red;
  z-index: 5000;

  .sl-map-select-list {
    height: 0px;
    width: 0px;
    position: relative;
    bottom: 138px;
    left: 140px;
  }
}
