$sl-stacked-area-chart: '.sl-stacked-area-chart';

#{$sl-stacked-area-chart} {
  width: 100%;

  &__tooltip {
    padding: 12px;
    background-color: white;
    border-radius: 4px;

    &-label {
      margin-bottom: 8px;
    }

    &-group {
      display: flex;
      padding: 4px 0;

      &-indicator {
        width: 14px;
        height: 14px;
        margin-right: 4px;
      }
    }
  }

  &__times {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 0;
    margin-bottom: 20px;
    &-switchers {
      width: 50%;
      margin-left: auto;
    }
  }
}