.Sl-filter-option-cont {
  max-width: 261px;
  padding-bottom: 2px;
  &:hover {
    background-color: rgb(25, 106, 212);
    cursor: pointer;
  }
  .Sl-filter-option {
    line-height: 18px;
    height: 24px;
    font-family: Wigrum;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    min-width: 214.25px;
    padding-right: 31px;
    padding-left: 32px;
    padding-top: 2px;
    color: rgba(255, 255, 255, 0.8);
    width: 261px;
    &:hover {
      cursor: pointer;
    }

    svg {
      float: right;
    }
  }
  .Sl-filter-option-count {
    height: 50px;
  }
  .Sl-filter-text {
    height: 24px;
    line-height: 24px;
  }
  .Sl-options-icon {
    opacity: 0.2;
  }
  .Sl-options-icon-on {
    opacity: 1;
  }

  .Sl-options-icon-rect {
    fill: rgb(255, 255, 255);
  }

  .Sl-options-icon-rect-on {
    fill: rgb(23, 227, 174);
  }

  .Sl-sub-filter-rel {
    position: relative;
    height: 0px;
    width: 0px;

    .Sl-sub-filter-abs {
      position: absolute;
      left: 230px;
      top: -80px;
    }
  }

  .Sl-filter-option-count {
    font-family: Wigrum;
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
  }
}
