@import "src/style/mixins";

:root {
  --fancy-bar-x-axis-width: 10px;
  --fancy-bar-colum-width: 8px;
}

.sl-stacked-bar {
  line-height: 1em;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
  @include paragraph--7();
  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  &__top {
    width: 100%;
    margin-bottom: 20px;
  }
  &__no-data {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    line-height: 20px;
    margin-top: -20px;
    margin-bottom: 0;
  }
  .contain {
    margin: 0 auto;
    padding-bottom: 22px;
    width: 100%;
    flex-grow: 1;
    height: 100%;
    @include responsive-min-md {
      padding-left: 50px;
    }
  }
  .bar-chart {
    margin: 0 auto;
    width: 100%;
    position: relative;
    height: 100%;
    &--no-data .x-axis .year .col .bar {
      border: 2px solid #8f9397;
    }
    sup {
      font-size: 10px;
    }
  }
  .bar-chart .bar-row {
    position: relative;
    line-height: 1.25em;
    margin-bottom: 3em;
    height: 100%;
  }
  .bar-chart .bar-row .segment {
    flex: 1 100%;
    display: block;
    position: relative;
    border-top: 1px dotted #333;
    box-sizing: border-box;
  }
  .bar-chart .bar-row .segment:after {
    content: "";
    display: block;
    width: 100%;
    bottom: 0;
    position: absolute;
    height: 1px;
    background-color: transparent;
    z-index: -1;
  }
  .bar-chart .label {
    display: block;
  }
  /* X Axis */
  .bar-chart .x-axis {
    display: flex;
    height: 100%;
  }
  .bar-chart .x-axis .year {
    flex-grow: 1;
    position: relative;
  }
  .bar-chart .x-axis .year .col {
    display: flex;
    height: 100%;
    flex-flow: column-reverse;
    margin: 0 auto;
    max-width: var(--fancy-bar-colum-width);
  }
  .bar-chart .x-axis .year .col .bar {
    background: linear-gradient(180deg, #ff7ab4 0%, #fa1478 100%);
    position: relative;
    height: 0;
    transition: height 0.5s ease;
    border-radius: 10px;
    cursor: pointer;
    margin: 0 1px;
  }
  .bar-chart .x-axis .year .col .bar + .bar {
    background-color: #cba11b;
  }
  .bar-chart .x-axis .label {
    position: absolute;
    text-align: center;
    width: 100%;
    white-space: nowrap;
  }

  .bar-chart .x-axis .year .col .bar .tooltip {
    top: -50px;
    line-height: 1;
    background: white;
    position: absolute;
    padding: 10px;
    color: var(--color-contrast-3);
    opacity: 0;
    transition: 0.3s linear opacity;
    z-index: 2;
    border-radius: 10px;
    pointer-events: none;
    display: block;
    left: 50%;
    transform: translateX(-50%);
    font-size: 13px;
    &__title {
      white-space: nowrap;
      p {
        margin: 0;
      }
    }
  }
  .bar-chart .x-axis .year .col .bar:hover .tooltip {
    opacity: 1;
  }
  /* Y Axis */
  .bar-chart .y-axis {
    display: flex;
    height: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    position: absolute;
    width: 100%;
    white-space: nowrap;
    border-left: 1px dotted #333;
    border-right: 1px dotted #333;
    border-bottom: 1px dotted #333;
  }
  .bar-chart .y-axis .label {
    min-height: 1px;
    width: 60px;
    text-align: left;

    @include responsive-min-md {
      margin: 0 auto -10px -72px;
      text-align: right;
    }
  }

  /* Legend */
  .bar-chart .legend {
    margin: 0 auto;
    text-align: center;
    padding: 0;
    display: none;
    font-size: 0.875em;
  }
  .bar-chart .legend li {
    display: inline-block;
    padding: 0.25em 1em;
    line-height: 1em;
  }
  .bar-chart .legend li:before {
    content: "";
    margin-right: 0.5em;
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #fa1478;
  }
  .bar-chart .legend li:nth-child(2):before {
    background-color: #ffc500;
  }
}
