$circle: '.Circle';

#{$circle} {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: var(--white);
  color: var(--color-secondary);
  justify-content: center;
  display: flex;
  align-items: center;
  font-weight: bold;
}
