.sl-input-form {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 40px;

  &--with-errors {
    padding-bottom: 16px;
  }

  &-error {
    position: absolute;
    left: 0;
    top: 48px;
    color: red;
    display: inline-block;
    padding-left: 46px;
    padding-top: 6px;
  }

  &-wrapper {
    .sl-text--subtitle--2m{
      cursor: auto !important;
    }
    .sl-input-form-title {
      margin-bottom: 18px;
    }
    .Sl-input-text {
      display: inline-block;
      margin-right: 16px;
      min-width: 337px;
      margin-left: 20px;
    }

    .sl-icon-and-text-button-button {
      display: inline-block;

      .sl-icon {
        top: 3px;
      }
    }

    .sl-icon {
      display: inline-block;
      position: relative;
    }

    svg {
      line-height: 48px;
    }
  }
}
