.Sl-table-cont {
  overflow: auto;
  margin-top: 50px;
  text-align: left;

  table {
    width: 100%;
    border-collapse: collapse;
    color: white;
    overflow-x: scroll;
    position: relative;
  }

  th,
  td,
  .sl-tableitem-value {
    white-space: nowrap;

    text-overflow: ellipsis;
  }

  th,
  td {
    padding: 15px;
    p {
      margin: 0;
    }
  }
  thead th {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    height: 50px;
    text-align: left;
  }

  tbody td {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: rgb(147, 149, 151);
  }

  tbody {
  }
  .sl-tablerow-cont {
    height: 40px;
  }
  .sl-tableitem-value {
    width: 100%;
  }
}

.sl-tableitem {
  white-space: nowrap;
  text-overflow: ellipsis;
}
