.SL-admin-checkbox-title {
  display: flex;
  flex-direction: row;

  .SL-admin {
    flex: 1;
  }
  .SL-admin-title-description-slider {
    flex: 11;
    .SL-admin-checkbox-title {
      margin-top: 5px;
      margin-bottom: 15px;
      text-transform: capitalize;
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 10px;
    }
    .sl-admin-notification-delay-desc {
      margin-bottom: 15px;
      color: #C6C6C6;
    }
  }
}
