html {
  font: 100%/1.2 wigrumweb, "Helvetica Neue", "Segoe UI", "Helvetica", "Arial",
    sans-serif;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

label {
  cursor: pointer;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  margin: 0;
  height: 100%;
  width: 100%;
}

body {
  background-color: rgb(17, 17, 17);
  margin: 0;
  color: var(--textPrimary);

  word-wrap: break-word;
  font-kerning: normal;
  font-feature-settings: "kern", "liga", "clig", "calt", "kern";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

dd,
dl,
ol,
ul {
  padding: 0;
  margin: 0 0 24px;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 9999px #fff;
}

:global(#root) {
  height: 100%;
  overflow: auto;
}
