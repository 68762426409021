.sl-selected-text-wrapper {
  cursor: pointer;
  .sl-selected-text-checkmark {
    width: 14px;
    margin-right: 8px;
    display: inline-block;
  }
  .sl-selected-text {
  }
  .selected-off {
    img {
      display: none;
    }
  }
}
